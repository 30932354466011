import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import urlConfigInternal            from 'shared/config/url-config-internal';

const icons = {
    WorkspacePremiumOutlinedIcon
};

const advertisingAffiliates = {
    id   : 'advertising-affiliates',
    title: 'Affiliates',
    type : 'item',
    url  : urlConfigInternal.advertisingAffiliates,
    icon : icons.WorkspacePremiumOutlinedIcon
};

export default advertisingAffiliates;
