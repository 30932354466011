import SafetyOutlined    from '@ant-design/icons/SafetyOutlined';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    SafetyOutlined
};

const roles = {
    id   : 'roles',
    title: 'Backend users roles [sa]',
    type : 'item',
    url  : urlConfigInternal.rolesManager,
    icon : icons.SafetyOutlined
};

export default roles;
