import {ApiParams} from 'shared/api/ApiResponse';
import appRequest  from 'shared/api/moduleApi';

interface currencyExchangeApiParam extends ApiParams {
    from: string,
    to: string,
    amount: string
}

const currencyExchangeApi = (params: currencyExchangeApiParam) => appRequest.get('currency-converter', params);

export default currencyExchangeApi;
