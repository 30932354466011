import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import urlConfigInternal  from 'shared/config/url-config-internal';

const icons = {
    ManageAccountsIcon
};

const playerProfiles = {
    id   : 'playerProfiles',
    title: 'Players [sa]',
    type : 'item',
    url  : urlConfigInternal.playerProfiles,
    icon : icons.ManageAccountsIcon
};

export default playerProfiles;
