import { Theme } from '@mui/material/styles/createTheme';

// Affect src/shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/NavItem.js

export default function ListItemButton(theme: Theme) {

    return {
        MuiListItemButton: {
            defaultProps  : {
            },
            styleOverrides: {
                root     : {
                    padding: 0,
                },
            }
        }
    };
}
