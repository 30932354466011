import ProtectedRoutes                from 'app/routes/ProtectedRoutes';
import PublicRoutes, {NoneExistRoute} from 'app/routes/PublicRoutes';
import { useRoutes }                  from 'react-router-dom';

export default function Router() {

    return useRoutes([
        NoneExistRoute,
        PublicRoutes,
        ProtectedRoutes
    ]);
}
