import { observer }  from 'mobx-react';
import React         from 'react';
import { Outlet }    from 'react-router-dom';
import AppSnackError from 'shared/ui/snack';

const MinimalLayout = () => (
    <>
        <AppSnackError />
        <Outlet />
    </>
);

export default observer(MinimalLayout);
