import DraftsIcon        from '@mui/icons-material/Drafts';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    DraftsIcon
};

const messagingTemplate = {
    id: 'messaging-template',
    title: 'Messaging Templates',
    type: 'item',
    url: urlConfigInternal.messagingTemplate,
    icon: icons.DraftsIcon
};

export default messagingTemplate;
