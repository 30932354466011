import Box              from '@mui/material/Box';
import Drawer           from '@mui/material/Drawer';
import { useTheme }     from '@mui/material/styles';
import useMediaQuery    from '@mui/material/useMediaQuery';
import PropTypes        from 'prop-types';
import { useMemo }      from 'react';
import { drawerWidth }  from 'shared/config/appConfig';
import DrawerContent    from 'shared/ui/layout/MainLayout/Drawer/DrawerContent';
import DrawerHeader     from 'shared/ui/layout/MainLayout/Drawer/DrawerHeader';
import MiniDrawerStyled from 'shared/ui/layout/MainLayout/Drawer/MiniDrawerStyled';
import { colorBase }    from 'shared/ui/themes/palette';

const MainDrawer = ({ open, handleDrawerToggle, window }) => {
    
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));
    
    // responsive drawer container
    const container = window !== undefined ? () => window().document.body : undefined;
    
    // header content
    const drawerContent = useMemo(() => <DrawerContent />, []);
    const drawerHeader = useMemo(() => <DrawerHeader open={open} />, [open]);
    
    return (
        <Box component='nav' sx={{ flexShrink: { md: 0 }, zIndex: 1300 }} aria-label='mailbox folders'>
            {!matchDownMD ? (
                <MiniDrawerStyled variant='permanent' open={open}>
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant='temporary'
                    open={open}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true,
                        slotProps: {
                            backdrop: { invisible: true }
                        }
                    }}
                    sx={{
                        display: { xs: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            // borderRight: `1px solid ${theme.palette.divider}`,
                            borderRight: `1px solid ${colorBase.MainDark2}`,
                            backgroundImage: 'none',
                            boxShadow: 'inherit',
                            backgroundColor: colorBase.MainDark1
                        }
                    }}
                >
                    {open && drawerHeader}
                    {open && drawerContent}
                </Drawer>
            )}
        </Box>
    );
};

MainDrawer.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func,
    window: PropTypes.object
};

export default MainDrawer;
