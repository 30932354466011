import {Theme}                from '@mui/material/styles/createTheme';
import {alabaster, colorBase} from 'shared/ui/themes/palette';

export default function Tooltip(theme: Theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: colorBase.MainDark1,
                    color          : alabaster
                },
            }
        }
    }
}
