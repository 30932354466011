import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import urlConfigInternal    from 'shared/config/url-config-internal';

const icons = {
    ShoppingCartOutlined
};

const accountingTransactionCategories = {
    id   : 'accounting-transaction-categories',
    title: 'Accounting Categories',
    type : 'item',
    url  : urlConfigInternal.accountingTransactionCategories,
    icon : icons.ShoppingCartOutlined
};

export default accountingTransactionCategories;
