import { Observer }    from 'mobx-react';
import React, { lazy } from 'react';
import { Navigate }    from 'react-router-dom';
import { loginPath }   from 'shared/config/url-config-internal';
import AppModel        from 'shared/model/AppModel';
import MinimalLayout   from 'shared/ui/layout/MinimalLayout';
import Loadable        from 'shared/ui/Loadable';

const LoginPage = Loadable(lazy(() => import('module/auth/ui/LoginPage')));
const PageNotFound = Loadable(lazy(() => import('shared/ui/layout/PageNotFound')));

const PublicRoutes = {
    path    : loginPath,
    element : <MinimalLayout />,
    children: [
        {
            path   : loginPath + '/',
            element: <LoginController><LoginPage /></LoginController>
        }
    ]
};
export default PublicRoutes;

export const NoneExistRoute = {
    path   : '*',
    element: <PageNotFound />
};


function LoginController({ children }: { children: React.JSX.Element }): React.JSX.Element {
    return (
        <Observer>{() => {
            return AppModel.isReady && AppModel.isAuthenticated ?
                (<Navigate to={AppModel.navCurrentGet} />) :
                (children);
        }}</Observer>
    );
}
