import { action, computed, makeObservable, observable, toJS } from 'mobx';
import React                                                  from 'react';

class TopBarActionsStore {
    constructor() {
        makeObservable(this, {
            actions     : observable,
            actionsGet  : computed,
            actionsHas  : computed,
            actionsSet  : action,
            actionsReset: action,
            _spin       : observable,
            spinIsStart : computed,
            spinStart   : action,
            spinStop    : action
        });
    }

    actions: React.ReactElement[] = [];

    _spin: boolean = false;

    get actionsGet(): React.ReactElement[] {
        return toJS(this.actions);
    }

    actionsSet(actions: React.ReactElement[]): void {
        this.actions = actions;
    }

    actionsReset(): void {
        this.actionsSet([]);
    }

    get actionsHas(): boolean {
        return (this.actionsGet?.length ?? 0) > 0;
    }

    get spinIsStart(): boolean {
        return toJS(this._spin);
    }

    spinStart(): void {
        this._spin = true;
    }

    spinStop(): void {
        this._spin = false;
    }
}

const TopBarActionsModel = new TopBarActionsStore();

export default TopBarActionsModel;
