import { appConfig } from 'shared/config/appConfig';
import { translate } from 'shared/lib/i18n/translator';

interface i18nPlan {
    [language: string]: string;
}


function fallbackLanguage(): string {
    return appConfig.i18n.default;
}

function plan(): i18nPlan {
    return appConfig.i18n.plan;
}


/**
 * Load from store or use fallback language
 * @todo implement user store
 */
export function getUserLanguage(): string {
    return fallbackLanguage();
}

export function getUserLocale(): string {
    return plan()[getUserLanguage()] ?? plan()[fallbackLanguage()];
}

export function _t(text?: string|any, translateOff: boolean = false): string {
    if (!text) {
        return '';
    }

    if ((text?.length ?? 0) > 0) {

        return translateOff ? text : translate(text);
    }

    return text;
}
