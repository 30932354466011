import {FormControlPropsSizeOverrides}  from '@mui/material/FormControl';
import {FormControlPropsColorOverrides} from '@mui/material/FormControl/FormControl';
import {FormControlClasses}             from '@mui/material/FormControl/formControlClasses';
import {Theme}                          from '@mui/material/styles';
import {SxProps}                        from '@mui/system';
import {OverridableStringUnion}         from '@mui/types';
import React                            from 'react';

export enum SysColors {
    Inherit   = 'inherit',
    Default   = 'default',
    Primary   = 'primary',
    Secondary = 'secondary',
    Error     = 'error',
    Info      = 'info',
    Success   = 'success',
    Warning   = 'warning'
}

export enum Visibility {
    Hidden  = 'hidden',
    Visible = 'visible',
}

export type ErrorValType = {
    errorVal?: string;
}

export type FormControlType = {
    children?: React.ReactNode;
    classes?: Partial<FormControlClasses>;
    color?: OverridableStringUnion<
        'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
        FormControlPropsColorOverrides
    >;
    disabled?: boolean;
    error?: boolean;
    fullWidth?: boolean;
    focused?: boolean;
    hiddenLabel?: boolean;
    margin?: 'dense' | 'normal' | 'none';
    required?: boolean;
    size?: OverridableStringUnion<'small' | 'medium', FormControlPropsSizeOverrides>;
    sx?: SxProps<Theme>;
    variant?: 'standard' | 'outlined' | 'filled';
}
