import PaidIcon          from '@mui/icons-material/Paid';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    PaidIcon
};

const brandBetWinTransactions = {
    id   : 'brandBetWinTransactions',
    title: 'Bet/Win Transactions',
    type : 'item',
    url  : urlConfigInternal.brandBetWinTransactions,
    icon : icons.PaidIcon
};

export default brandBetWinTransactions;
