import WebhookIcon       from '@mui/icons-material/Webhook';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    WebhookIcon
};

const freespinOfferManager = {
    id   : 'freespinOfferManager',
    title: 'Freespin Offers',
    type : 'item',
    url  : urlConfigInternal.freespinOfferManager,
    icon : icons.WebhookIcon
};

export default freespinOfferManager;
