import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import urlConfigInternal      from 'shared/config/url-config-internal';

const icons = {
    AccessTimeOutlinedIcon
};

const shiftEmployeeMonthPlan = {
    id   : 'shiftEmployeeMonthPlan',
    title: 'Employee shift month plan',
    type : 'item',
    url  : urlConfigInternal.shiftEmployeeMonthPlan,
    icon : icons.AccessTimeOutlinedIcon
};

export default shiftEmployeeMonthPlan;
