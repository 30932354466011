export default function FormControlLabel(theme: any) {
    return {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    // color        : theme.palette.grey[600],
                    textTransform: 'capitalize',
                },
                // Affect Switch label
                label: {
                    color        : theme.palette.grey[600],
                    textTransform: 'capitalize',
                    fontSize     : '0.75rem'
                }
            }
        }
    };
}
