import React from 'react';

export type HandbookKey = string | number;
export type HandbookVal = string;

export enum hbIndex {
    k = 'k',
    v = 'v',
    p = 'p',
}

export type HandbookElement = {
    [hbIndex.k]: HandbookKey,
    [hbIndex.v]: HandbookVal,
    [hbIndex.p]?: HandbookKey
}

export type HandbookElementComponent = {
    [hbIndex.k]: HandbookKey,
    [hbIndex.v]: React.JSX.Element,
}

export type Handbook = HandbookElement[];

export type HandbookKeyList = HandbookKey[];
export type HandbookCollection = Record<string, Handbook>;
export type HandbookContainer = {
    handbook: HandbookCollection,
    handbookBag: any,
    dictionary: any
}
export type HandbookNormalised = Record<HandbookKey, string>;
export type HandbookNormalisedCollection = Record<string, HandbookNormalised>
export type ElementMap = Record<string, React.JSX.Element>

export type AlfaNumListType = string[] | number[];
export type AlfaNumType = string | number;

export type HandbookObj = Record<number, string>;

export type DictionaryValue = string | React.JSX.Element;
export type Dictionary = Record<HandbookKey, DictionaryValue>;

export enum HbMetaAlias {
    Integrator = 'integrator',
}
