import { action, computed, makeAutoObservable, observable, toJS } from 'mobx';

class ClockModel {
    date = Date.now();

    constructor() {
        this.date = Date.now();
        makeAutoObservable(this, {
            date   : observable,
            dateSet: action,
            current: computed
        });
    }

    get current() {
        return toJS(this.date);
    }

    dateSet(date: number): void {
        this.date = date;
    }
}

const ClockStore = new ClockModel();

export default ClockStore;
