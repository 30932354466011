import Typography           from '@mui/material/Typography';
import { Observer }         from 'mobx-react';
import React, { useEffect } from 'react';
import ClockStore           from 'shared/ui/Clock/ClockStore';

/**
 *@todo to widget
 */
const Clocks = () => {

    useEffect(() => {
        const timer = setInterval(() => {
            ClockStore.dateSet(Date.now());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, []);

    const pad = (n: number) => (n < 10 ? `0${n}` : n);

    const format = (t: Date) =>
        `${pad(t.getUTCHours())}:${pad(t.getUTCMinutes())}:${pad(t.getUTCSeconds())}`;

    const currentTime = (): any => {
        if (!ClockStore.current) {
            return '';
        }

        return format(new Date(ClockStore.current));
    };

    return (
        <Typography sx={{ fontSize: '.75rem', pr: 1, pl: 1 }}>
            <Observer>{() => currentTime()}</Observer>
        </Typography>
    );
};

export default Clocks;
