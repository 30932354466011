import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import urlConfigInternal      from 'shared/config/url-config-internal';

const icons = {
    AccessTimeOutlinedIcon
};

const shiftScheduleManager = {
    id   : 'shiftScheduleManager',
    title: 'Shift schedule manager',
    type : 'item',
    url  : urlConfigInternal.shiftScheduleManager,
    icon : icons.AccessTimeOutlinedIcon
};

export default shiftScheduleManager;
