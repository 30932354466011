import ButtonBase     from '@mui/material/ButtonBase';
import Container      from '@mui/material/Container';
import PropTypes      from 'prop-types';
import { Link }       from 'react-router-dom';
import { appConfig }  from 'shared/config/appConfig';
import { MuiSxProps } from 'shared/ui/ComponentProps';
import Logo           from 'shared/ui/Logo/Logo';

interface LogoSectionProps extends MuiSxProps {
    disabled: boolean;
}

const LogoSection = ({ sx, disabled = false }: LogoSectionProps) => (
    <Container sx={{ p: 0, m: 0, justifyContent: 'center', display: 'flex' }}>
        <ButtonBase disabled={disabled} disableRipple component={Link} to={appConfig.defaultPath} sx={sx}>
            <Logo />
        </ButtonBase>
    </Container>
);

LogoSection.propTypes = {
    sx: PropTypes.object
};

export default LogoSection;
