import Box                            from '@mui/material/Box';
import {observer}                     from 'mobx-react';
import React                          from 'react';
import TopBarActions                  from 'shared/model/TopBarActionsModel';
import Clocks                         from 'shared/ui/Clock/Clocks';
import Profile                        from 'shared/ui/layout/MainLayout/Header/HeaderContent/Profile';
import CurrencyConverterWidgetElement from 'widgets/Tools/currency-converter/CurrencyConverterWidgetElement';

const HeaderContent = () => {

    const topBarActions = TopBarActions.actionsGet;

    return <>

        <Box sx={{width: '100%', ml: {xs: 1, md: 1}}}>
            {topBarActions.length > 0 ? topBarActions : null}
        </Box>

        <CurrencyConverterWidgetElement/>

        <Clocks/>

        <Profile/>
    </>;
};

export default observer(HeaderContent);
