export default function Select() {
    return {
        MuiSelect: {
            styleOverrides: {
                root: {
                    lineHeight: '1.2em'
                }
            }
        }
    };
}
