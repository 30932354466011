import Navigation from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation';
import SimpleBar  from 'shared/ui/third-party/SimpleBar';

const DrawerContent = () => (
    <SimpleBar
        sx={{
            '& .simplebar-content': {
                display      : 'flex',
                flexDirection: 'column'
            }
        }}
    >
        <Navigation />
    </SimpleBar>
);

export default DrawerContent;
