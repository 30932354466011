import accountingTransaction           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/accountingTransaction';
import accountingTransactionCategories from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/accountingTransactionCategories';
import advertisingAffiliates           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/advertisingAffiliates';
import brandBetWinTransactions         from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/brandBetWinTransactions';
import brandDepositTransactions        from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/brandDepositTransactions';
import brandWithdrawalTransactions     from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/brandWithdrawalTransactions';
import freespinOfferManager            from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/freespinOfferManager';
import gameBrandProvider               from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/gameBrandProvider';
import gameBrands                      from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/gameBrands';
import gameIntegrator                  from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/gameIntegrator';
import gameProvider                    from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/gameProvider';
import games                           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/games';
import gameTags                        from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/gameTags';
import messagingTemplatesManager       from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/messagingTemplate';
import organizationManager             from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/organizationManager';
import paymentSystemBrand              from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/paymentSystemBrand';
import paymentSystemMethod             from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/paymentSystemMethod';
import paymentSystemMethodBrand        from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/paymentSystemMethodBrand';
import paymentSystems                  from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/paymentSystems';
import playerFreespinByBrand           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/playerFreespinByBrand';
import playerProfiles                  from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/playerProfiles';
import protoSegmentation               from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/protoSegmentation';
import roles                           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/roles';
import shiftEmployeeMonthPlan          from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/shiftEmployeeMonthPlan';
import shiftScheduleManager            from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/shiftScheduleManager';
import users                           from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items/users';

const menuItems = {
    items: [
        protoSegmentation,

        messagingTemplatesManager,
        playerProfiles,
        brandBetWinTransactions,
        brandDepositTransactions,
        brandWithdrawalTransactions,
        playerFreespinByBrand,

        freespinOfferManager,

        gameIntegrator,
        gameProvider,
        gameBrandProvider,
        games,
        gameBrands,
        gameTags,

        paymentSystems,
        paymentSystemBrand,
        paymentSystemMethod,
        paymentSystemMethodBrand,

        accountingTransactionCategories,
        accountingTransaction,

        advertisingAffiliates,

        shiftScheduleManager,
        shiftEmployeeMonthPlan,

        organizationManager,
        users,
        roles
    ]
};

export default menuItems;
