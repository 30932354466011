import { Theme }                         from '@mui/material/styles/createTheme';
import { TableBorder, TableBorderColor } from 'shared/ui/themes/palette';

export default function Table(theme: Theme) {
    return {
        MuiTable: {
            styleOverrides: {
                root: {
                    borderRight     : TableBorder,
                    borderRightColor: TableBorderColor
                }
            }
        }
    };
}
