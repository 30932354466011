import LocationCityIcon  from '@mui/icons-material/LocationCity';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    LocationCityIcon
};

const organizationManager = {
    id   : 'organizationManager',
    title: 'Organizations',
    type : 'item',
    url  : urlConfigInternal.organizationManager,
    icon : icons.LocationCityIcon
};

export default organizationManager;
