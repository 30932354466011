import {DollarOutlined}       from '@ant-design/icons';
import IconButton             from '@mui/material/IconButton';
import React                  from 'react';
import CurrencyConverterStore from 'widgets/Tools/currency-converter/CurrencyConverterModel';


const CurrencyConverterWidgetElement = () =>
    <IconButton
        onClick={() => {CurrencyConverterStore.widgetOpen()}}
        sx={{color: 'success.main'}}
    >
        <DollarOutlined/>
    </IconButton>;

export default CurrencyConverterWidgetElement;
