import {getUserLocale} from 'shared/lib/i18n/I18n';

// need to test for negative (-50 is not formatted)
export function numberFormat(val: number , separator: string = ' ') {
    // remove sign if negative
    let sign = 1;
    if (val < 0) {
        sign = -1;
        val  = -val;
    }
    // trim the number decimal point if it exists
    let num    = val.toString().includes('.') ? val.toString().split('.')[0] : val.toString();
    let len    = num.toString().length;
    let result = '';
    let count  = 1;

    for (let i = len - 1; i >= 0; i--) {
        result = num.toString()[i] + result;
        if (count % 3 === 0 && count !== 0 && i !== 0) {
            result = separator + result;
        }
        count++;
    }

    // add number after decimal point
    if (val.toString().includes('.')) {
        result = result + '.' + val.toString().split('.')[1];
    }
    // return result with - sign if negative
    return sign < 0 ? '-' + result : result;
}

export function numberFormatByLocale(n: number | string, minimumFractionDigits = 2) {
    n        = Number(n)
    let opts = {minimumFractionDigits: minimumFractionDigits};

    return n.toLocaleString(getUserLocale(), opts);
}

export function numberFormatByCurrency(n: number, currency = 'EUR', minimumFractionDigits = 2) {

    let opts = {
        minimumFractionDigits: minimumFractionDigits,
        style                : 'currency',
        currency             : currency
    };

    return n.toLocaleString(getUserLocale(), opts);
}
