import {Theme} from '@mui/material/styles/createTheme';

export default function TablePagination(theme: Theme) {
    return {
        MuiTablePagination: {
            styleOverrides: {
                'root'         : {
                    '& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar': {
                        minHeight: '24px',
                        height   : '24px'
                    },
                },
                'input'        : {
                    fontSize             : '0.75rem',
                    padding              : 0,
                    marginLeft           : '2px',
                    marginRight          : '8px',
                    minWidth             : '50px',
                    '& .MuiSelect-select': {
                        margin  : 0,
                        padding : 0,
                        position: 'relative',
                        top     : '2px',
                    }
                },
                'selectLabel'  : {
                    fontSize: '0.65rem',
                    margin  : 0,
                    padding : 0,
                    position: 'relative',
                    top     : '1px',
                },
                'displayedRows': {
                    margin     : 0,
                    marginRight: '4px',
                    padding    : 0,
                    minWidth   : '86px',
                    fontSize   : '0.75rem',
                    position   : 'relative',
                    top        : '2px'
                },
                'actions'      : {
                    margin                           : 0,
                    padding                          : 0,
                    fontSize                         : '0.65rem',
                    '& .MuiIconButton-sizeMedium'    : {
                        height: '20px',
                        width : '26px',
                    },
                    '& svg.MuiSvgIcon-fontSizeMedium': {
                        // height: '10px'
                        fontSize: '1.1rem',
                    }
                }
            }
        }
    }
}
