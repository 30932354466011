export default function Autocomplete() {
    return {
        MuiAutocomplete: {
            styleOverrides: {
                endAdornment: {
                    // top: 'calc(50% - 20px)'
                    '& button.MuiAutocomplete-clearIndicator'    : {
                        top  : '-18px',
                        right: '-20px'
                    },
                    '& button.MuiAutocomplete-clearIndicator svg': {
                        fontSize: '.65rem',
                        color   : 'rgba(0, 0, 0, 0.25)',
                    },
                },
                // tag               : {
                //     height: 22,
                //     margin: 1
                // },
                clearIndicator    : {
                    width : 10,
                    height: 10,
                },
                popupIndicator    : {
                    width : 24,
                    height: 26,
                },
                popupIndicatorOpen: {
                    width : 24,
                    height: 24
                },
                popper            : {
                    minWidth: '200px'
                },
                root              : {
                    '&.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot': {
                        paddingRight: '16px'
                    },
                    '&.MuiAutocomplete-hasPopupIcon .MuiInputBase-root input'                               : {
                        padding: '0.5px',
                    },
                }
            }
        }
    };
}
