import IntlMessageFormat from 'intl-messageformat';

type TranslationMap = {
    [alias: string | number]: string,
};

let translationMap: any = {
    'company_id'     : 'Company',
    'owner_id'       : 'Company',
    'project_id'     : 'Brand',
    'brandId'        : 'Brand',
    'providerId'     : 'Provider',
    'countryId'      : 'Country',
    'created_at'     : 'Created',
    'createdAt'      : 'Created',
    'modifiedAt'     : 'Modified',
    'modified_at'    : 'Modified',
    'updated_at'     : 'Modified',
    'expiresAt'      : 'Expires',
    'completedAt'    : 'Completed',
    'app.login': 'Login',
    'app.empty.value': ' &nbsp;',
    // 'app.required'     : 'This value should not be blank.',
    'app.required'     : ' ',
    'app.action.edit'  : 'Edit',
    'app.action.create': 'Create',
    'app.action.clone' : 'Clone',
    'launch_code'      : 'Launch code',
    'external_code'    : 'External code',
    'payment_system'   : 'Payment System',
    'paysystemId'      : 'Payment System',
    't.enabled'        : 'Enabled',
    't.disabled'       : 'Disabled',
    'app.inconvenience': 'Sorry for the inconvenience. Try later.',
    'integratorId'     : 'Integrator'
};

function setTranslations(translations: TranslationMap, locale: string) {

    translationMap = Object.keys(translations).reduce((messages: any, translationKey) => {
        // @todo add locale for correct translation of numbers, dates, ...
        try {
            messages[translationKey] = new IntlMessageFormat(translations[translationKey], locale);
        } catch (e: any) {
            console.error(
                'The translation key ' + translationKey + ' could not be translated. ' +
                'It is translated to "' + translations[translationKey] + '" which is an invalid IntlMessageFormat: ' +
                e.toString()
            );
        }

        return messages;
    }, {});
}

function clearTranslations() {
    translationMap = {};
}

function translate(key: string | number): string {

    return translationMap?.[key] ?? key;
}

export {
    clearTranslations,
    setTranslations,
    translate
};
