import HandbookAbstract                                 from 'shared/handbook/HandbookAbstract';
import * as brandSchema                                 from 'shared/types/brand/BrandSchema';
import {BrandCollection, BrandCollectionItem}           from 'shared/types/brand/BrandType';
import * as companySchema                               from 'shared/types/company/CompanySchema';
import {CompanyHb}                                      from 'shared/types/company/CompanyType';
import {Dictionary, Handbook, HandbookKey, HandbookVal} from 'shared/types/Handbook';

type CompanyBrandMapType = Record<number, Handbook>;

export default class AppHandbook extends HandbookAbstract {

    private _brandCollection: BrandCollection;
    private _hbBrands: Handbook | undefined;
    private _hbCompanies: Handbook | undefined;
    private _dictCompanies: Dictionary | undefined;

    private _kvCurrencyConverter: Handbook | undefined;

    constructor() {
        super();
        this._brandCollection     = this.brands;
        this._hbBrands            = undefined;
        this._hbCompanies         = undefined;
        this._kvCurrencyConverter = undefined;
    }

    get brandedOrganizationKv(): Handbook {
        return this._getHandbook('brandedOrganization');
    }

    defineBrandedOrganization(k: HandbookKey): HandbookVal {
        return this._defineFromHb(this.brandedOrganizationKv, k);
    }

    get kvCurrencyConverter(): Handbook {
        return this._getHandbook('currencyConverter');
    }

    /**
     * @deprecated
     */
    get companies(): CompanyHb {
        return this._getBag('companies');
    }

    /**
     * @deprecated
     */
    get hbCompanies(): Handbook {
        if (!this._hbCompanies) {
            this._hbCompanies = this._buildHandbook(this.companies, companySchema.pk, companySchema.name);
        }
        return this._hbCompanies;
    }

    /**
     * @deprecated
     */
    get dictCompanies(): Dictionary {
        if (!this._dictCompanies) {
            this._dictCompanies = {};
            for (let i = 0; i < this.hbCompanies.length; i++) {
                let company                    = this.hbCompanies[i];
                this._dictCompanies[company.k] = company.v ?? '';
            }
        }

        return this._dictCompanies;
    }

    /**
     * @deprecated
     */
    get brands(): BrandCollection {
        if (!this._brandCollection) {
            this._brandCollection = this._getBag('brands');
        }
        return this._brandCollection;
    }

    /**
     * @deprecated
     */
    defineBrand(key: number): HandbookVal {
        return this._defineFromHb(this.hbBrands, key);
    }

    /**
     * @deprecated
     */
    defineCompanyKeyFromBrands(brandKey: number): number {
        if (!brandKey) {
            return 0;
        }

        for (let i = 0; i < this.brands.length; i++) {
            if (this.brands[i].id === brandKey) {
                return this.brands[i].owner_id;
            }
        }

        return 0;
    }

    /**
     * @deprecated
     */
    defineCompanyByBrand(brandKey: number): any {
        if (!brandKey) {
            return '';
        }

        let companyKey = this.defineCompanyKeyFromBrands(brandKey);
        if (!companyKey) {
            return '';
        }


        return this._defineFromHb(this.hbCompanies, companyKey);
    }

    /**
     * @deprecated
     */
    get hbBrands(): Handbook {
        if (!this._hbBrands) {
            this._hbBrands = this._buildHandbook(this.brands, companySchema.pk, companySchema.name);
        }
        return this._hbBrands;
    }

    /**
     * @deprecated
     */
    get mapCompanyBrand(): CompanyBrandMapType {
        let brandMap: {
            [k: string]: any
        } = {};
        // this.brands.forEach((brand: BrandCollectionItem) => {
        this.brands?.forEach((brand: BrandCollectionItem) => {
            let company: number     = brand[brandSchema.companyId];
            let brandList: Handbook = brandMap?.[company] ?? [];
            brandList.push({
                'k': brand[brandSchema.pk],
                'v': brand[brandSchema.title]
            });

            brandMap[company] = brandList;
        });

        return brandMap;
    }

    /**
     * @deprecated
     * @todo rename getBrandMapByCompany
     */
    getProjectMapByCompany(companyId: number): Handbook {
        return this.mapCompanyBrand?.[companyId] ?? [];
    }
}
