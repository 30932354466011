import { Theme }                         from '@mui/material/styles/createTheme';
import { TableBorder, TableBorderColor } from 'shared/ui/themes/palette';

export default function TableCell(theme: Theme) {
    return {
        MuiTableCell: {
            styleOverrides: {
                root: {},
                head: {
                    fontWeight   : 600,
                    // paddingTop   : '3px',
                    // paddingRight : 0,
                    // paddingBottom: 0,
                    // paddingLeft  : 0
                },
                body: {
                    fontSize         : 14,
                    borderLeft       : TableBorder,
                    borderLeftColor  : TableBorderColor,
                    borderBottomColor: TableBorderColor,
                    whiteSpace       : 'normal !important',
                    wordBreak        : 'break-all',
                    lineHeight       : 1.5
                }
            }
        }
    };
}
