import { sortKvByV }              from 'shared/handbook/handbookHelper';
import { buildHandbook, emptyHb } from 'shared/handbook/handbookHelper';
import {
    Handbook,
    HandbookCollection,
    HandbookContainer,
    HandbookKey,
    HandbookNormalised,
    HandbookNormalisedCollection,
    HandbookVal
}                                 from 'shared/types/Handbook';

export default abstract class HandbookAbstract {
    protected _h: HandbookCollection;
    protected _n: HandbookNormalisedCollection;
    protected _b: any;
    protected _d: any;
    protected _f: any;

    constructor() {
        this._h = {};
        this._n = {};
        this._b = {};
        this._d = {};
        this._f = {};
    }

    set dataInit(data: HandbookContainer) {
        const { handbook, handbookBag, dictionary, gridFilter }: {
            handbook: HandbookCollection,
            handbookBag: any,
            dictionary: any,
            gridFilter?: any
        } = data;

        this._h = this._sortKvs(handbook);
        this._b = handbookBag;
        this._d = dictionary;
        this._normalizeHandbook();
        this._f = gridFilter ?? null;
    }

    private _sortKvs(handbookList: HandbookCollection): HandbookCollection {
        let hbList: HandbookCollection = {};
        Object.keys(handbookList).forEach((alias) => {
            hbList[alias] = sortKvByV(handbookList[alias]);
        });

        return hbList;
    }

    private _normalizeHandbook(): void {
        if (!Object.keys(this._h).length)
            return;

        Object.keys(this._h).forEach((alias) => {
                let kvHb = this._h[alias];
                if (kvHb)
                    this._n[alias] = this._normalise(kvHb);
            }
        );
    }

    private _normalise(data: Handbook): HandbookNormalised {
        let dataMap: HandbookNormalised = {};
        Object.entries(data).forEach(([i, obj]) => dataMap[obj.k] = obj.v);

        return dataMap;
    }

    protected _buildHandbook(data: any, k: string, v: string): Handbook {

        return buildHandbook(data, k, v);
    }

    protected _getNormalised(alias: string): HandbookNormalised | undefined {
        return this._n[alias] ?? undefined;
    }

    protected _define(alias: string, key: HandbookKey): string {
        return this._getNormalised(alias)?.[key] ?? '';
    }

    protected _getBag(alias: string): any {
        return this._b[alias] ?? undefined;
    }

    protected _getDictionary(alias: string): any {
        return this._d[alias] ?? undefined;
    }

    protected _getHandbook(alias: string): Handbook {
        return this._h[alias] ?? emptyHb;
    }

    protected _getFilter(alias: string): any {
        return this._f[alias] ?? undefined;
    }

    protected _defineFromHb(hb: Handbook, key: HandbookKey, def: HandbookVal = ''): HandbookVal {
        if (key === 0 || key === '') {
            return def;
        }

        for (let i = 0; i < hb.length; i++) {
            if (hb[i].k === key) {
                return hb[i].v;
            }
        }

        return '';
    }
}
