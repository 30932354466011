import InterestsOutlinedIcon from '@mui/icons-material/InterestsOutlined';
import urlConfigInternal     from 'shared/config/url-config-internal';

const icons = {
    InterestsOutlinedIcon
};

const protoSegmentation = {
    id: 'proto-segmentation',
    title: 'Segmentation',
    type: 'item',
    url: urlConfigInternal.protoSegmentation,
    icon: icons.InterestsOutlinedIcon
};

export default protoSegmentation;