import { makeAutoObservable } from 'mobx';

class EditorSemaphoreModel {

    private _processing: boolean;

    constructor() {
        this._processing = false;
        makeAutoObservable(this);
    }

    get isProcessing(): boolean {
        return this._processing;
    }

    start(): void {
        this._processing = true;
    }

    stop(): void {
        this._processing = false;
    }
}

const EditorSemaphore = new EditorSemaphoreModel();

export default EditorSemaphore;
