import {makeAutoObservable} from 'mobx';

class CurrencyConverterModel {

    private _from: string;
    private _to: string;
    private _amount: string;

    private _loading: boolean;
    private _errorMessage: string;
    private _amount_ret: string;
    private _ratio_ret: string;

    private _widgetOpen: boolean;

    constructor() {
        makeAutoObservable(this);

        this._from         = 'USD';
        this._to           = 'EUR';
        this._amount       = '1';
        this._loading      = false;
        this._errorMessage = '';
        this._amount_ret   = '0';
        this._ratio_ret    = '0';
        this._widgetOpen   = false;
    }

    modelReset(): void {
        this._from         = 'USD';
        this._to           = 'EUR';
        this._amount       = '1';
        this._loading      = false;
        this._errorMessage = '';
        this._amount_ret   = '0';
        this._ratio_ret    = '0';
    }

    set from(from: string) {
        this._from = from;
    }

    get from(): string {
        return this._from;
    }

    set to(to: string) {
        this._to = to;
    }

    get to(): string {
        return this._to;
    }

    set amount(amount: string) {
        this._amount = amount;
    }

    get amount(): string {
        return this._amount;
    }

    set loading(loading: boolean) {
        this._loading = loading;
    }

    get loading(): boolean {
        return this._loading;
    }

    set amountRet(amount: string) {
        this._amount_ret = amount;
    }

    get amountRet(): string {
        return this._amount_ret;
    }

    get amountRetCalculated() {
        return this.amountRet;
    }

    set ratioRet(amount: string) {
        this._ratio_ret = amount;
    }

    get ratioRet(): string {
        return this._ratio_ret;
    }

    set errorMessage(msg: string) {
        this._errorMessage = msg;
    }

    get errorMessage(): string {
        return this._errorMessage;
    }

    widgetOpen(): void {
        this._widgetOpen = true;
    }

    widgetClose(): void {
        this._widgetOpen = false;
    }

    get widget(): boolean {
        return this._widgetOpen
    }
}

const CurrencyConverterStore = new CurrencyConverterModel();

export default CurrencyConverterStore;
