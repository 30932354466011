import CssBaseline          from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import createTheme          from '@mui/material/styles/createTheme';
import ThemeProvider        from '@mui/material/styles/ThemeProvider';
import { useMemo }          from 'react';
import componentsOverride   from 'shared/ui/themes/overrides';
import Palette              from 'shared/ui/themes/palette';
import CustomShadows        from 'shared/ui/themes/shadows';
import Typography           from 'shared/ui/themes/typography';

export default function ThemeCustomization({ children }: { children: any }) {
    const theme = Palette('light');

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const themeTypography = Typography(`'Public Sans', sans-serif`);
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);

    const themeOptions = useMemo(
        () => ({
            breakpoints  : {
                values: {
                    xs: 0,
                    sm: 768,
                    md: 1024,
                    lg: 1266,
                    xl: 1536
                }
            },
            direction    : 'ltr',
            mixins       : {
                toolbar: {
                    minHeight    : 60,
                    paddingTop   : 8,
                    paddingBottom: 8
                }
            },
            palette      : theme.palette,
            customShadows: themeCustomShadows,
            typography   : themeTypography
        }),
        [theme, themeTypography, themeCustomShadows]
    );

    // @ts-ignore
    const themes = createTheme(themeOptions);
    // @ts-ignore
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
