import {Theme}          from '@mui/material/styles/createTheme';
import Autocomplete     from 'shared/ui/themes/overrides/Autocomplete';
import Badge            from 'shared/ui/themes/overrides/Badge';
import Button           from 'shared/ui/themes/overrides/Button';
import CardContent      from 'shared/ui/themes/overrides/CardContent';
import Checkbox         from 'shared/ui/themes/overrides/Checkbox';
import Chip             from 'shared/ui/themes/overrides/Chip';
import FormControlLabel from 'shared/ui/themes/overrides/FormControlLabel';
import FormHelperText   from 'shared/ui/themes/overrides/FormHelperText';
import Grid2            from 'shared/ui/themes/overrides/Grid2';
import IconButton       from 'shared/ui/themes/overrides/IconButton';
import InputBase        from 'shared/ui/themes/overrides/InputBase';
import InputLabel       from 'shared/ui/themes/overrides/InputLabel';
import LinearProgress   from 'shared/ui/themes/overrides/LinearProgress';
import Link             from 'shared/ui/themes/overrides/Link';
import ListItemButton   from 'shared/ui/themes/overrides/ListItemButton';
import ListItemIcon     from 'shared/ui/themes/overrides/ListItemIcon';
import OutlinedInput    from 'shared/ui/themes/overrides/OutlinedInput';
import Select           from 'shared/ui/themes/overrides/Select';
import Tab              from 'shared/ui/themes/overrides/Tab';
import Table            from 'shared/ui/themes/overrides/Table';
import TableCell        from 'shared/ui/themes/overrides/TableCell';
import TablePagination  from 'shared/ui/themes/overrides/TablePagination';
import Tooltip          from 'shared/ui/themes/overrides/Tooltip';
import Tabs             from 'shared/ui/themes/overrides/Tabs';
import Typography       from 'shared/ui/themes/overrides/Typography';

export default function ComponentsOverrides(theme: Theme) {
    return {
        ...FormHelperText(theme),
        ...Tooltip(theme),
        ...TablePagination(theme),
        ...Grid2(theme),
        ...Button(theme),
        ...ListItemButton(theme),
        ...Badge(theme),
        ...CardContent(),
        ...Checkbox(theme),
        ...Chip(theme),
        ...IconButton(theme),
        ...InputLabel(theme),
        ...FormControlLabel(theme),
        ...LinearProgress(),
        ...Link(),
        ...ListItemIcon(),
        ...OutlinedInput(theme),
        ...Tab(theme),
        ...Table(theme),
        ...TableCell(theme),
        ...Tabs(theme),
        ...Typography(),
        ...Autocomplete(),
        ...InputBase(),
        ...Select()
    };
}
