import { action, makeAutoObservable, toJS } from 'mobx';
import appRequest                           from 'shared/api/moduleApi';
import { appApiConfig }                     from 'shared/config/appConfig';
import { homePath, loginPath }              from 'shared/config/url-config-internal';
import AppHandbook                          from 'shared/model/AppHandbook';
import { Handbook, HandbookContainer }      from 'shared/types/Handbook';

type NavApiMapType = {
    c: string;
    s: string;
};

type AppUser = {
    name: string
    locale: string
    position: string
    company: string,
    companyKey: number,
    organizationKey: number,
    brandKeys: number[],
    brandKey: number,
    hasFullAccess: boolean
}

const userDefault: AppUser = {
    name: '',
    locale: '',
    position: '',
    company: '',
    companyKey: 0,
    organizationKey: 0,
    brandKeys: [],
    brandKey: 0,
    hasFullAccess: false
};

interface IAppData {
    hash: string;
    nav_api_map: NavApiMapType[];
    user: AppUser;
    hb: HandbookContainer;
}

const extractNavigationUrl = (data: NavApiMapType[]): string[] => {
    let list: string[] = [];
    data.map((v) => list.push(v.c));

    return list;
};

class AppStore {

    private _hash: string;
    private _isAuthenticated: boolean;
    private _nav: String[];
    private _navMap: NavApiMapType[];
    private _loading: boolean;
    private _appError: string | undefined;
    private _appDebugMessage: string | undefined;
    private _user: AppUser;
    private _hb: AppHandbook;

    constructor() {
        this._hash = '';
        this._isAuthenticated = false;
        this._nav = [];
        this._navMap = [];
        this._loading = false;
        this._user = userDefault;
        this._hb = new AppHandbook();

        makeAutoObservable(this);
    }

    private _defaultsInit(): void {
        this._hash = '';
        this._isAuthenticated = false;
        this._nav = [];
        this._navMap = [];
        this._loading = false;
        this._user = userDefault;
    }

    shutdown(): void {
        this._defaultsInit();
        if (window.location.pathname !== loginPath) {
            window.location.href = loginPath;
        }
    }

    boot(onSuccess?: any): boolean {

        if (this.isReady || this.isLoading) {
            return false;
        }

        this.loadingStart();

        appRequest
            .get(appApiConfig.url + 'meta')
            .then(action((metaData) => {
                try {
                    if (this._dataSet(metaData?.data ?? {})) {
                        this._isAuthenticated = true;
                        if (onSuccess) {
                            onSuccess();
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            }))
            .finally(action(() => {
                this.loadingStop();
            }))
            .catch((e) => {
            });

        return true;
    }

    /**
     * @deprecated
     * @todo Inspect usage
     * @param onSuccess
     */
    bootWithFetch(onSuccess?: any): boolean {

        if (this.isReady || this.isLoading) {
            return false;
        }

        this.loadingStart();

        fetch(appApiConfig.url + 'meta', {
            redirect: 'manual',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'same-origin'
        })
            .then((response) => {
                return response.json();
            })
            .then(action((metaData) => {
                if (this._dataSet(metaData?.data ?? {})) {
                    this._isAuthenticated = true;
                    if (onSuccess) {
                        onSuccess();
                    }
                }
            }))
            .finally(action(() => {
                this.loadingStop();
            }))
            .catch(() => {
            });

        return true;
    }

    get isAuthenticated(): boolean {
        return this._isAuthenticated;
    }

    private _dataSet(data: IAppData): boolean {

        const { hash, nav_api_map, user, hb } = data;

        if (hash === this._hash) {
            return true;
        }

        this._hash = hash;
        this._user = user;

        // const {brandKeys}     = user;
        // const brandKeysSorted = brandKeys.sort((a, b) => a - b);
        // this._user.brandKeys  = brandKeysSorted;
        // this._user.brandKey   = brandKeysSorted?.[0] ?? 0;
        // this._user.brandKey   = brandKeys?.[0] ?? 0;

        if (nav_api_map) {
            this._navSet(nav_api_map);
            this._hb.dataInit = hb;

            return true;
        }

        return false;
    }

    private get isLoading(): boolean {
        return this._loading;
    }

    private loadingStart(): void {
        this._loading = true;
    }

    private loadingStop(): void {
        this._loading = false;
    }

    get isReady(): boolean {
        return toJS(this._nav).length > 0;
    }

    get navList(): Array<String> {
        return this._nav;
    }

    private _navSet(navMap: NavApiMapType[]): void {
        this._navMap = navMap;
        this._nav = extractNavigationUrl(navMap);
    }

    private navReset(): void {
        this._navSet([]);
    }

    navIsAllowed(nav: string): boolean {
        if (!this.isReady) {
            return false;
        }

        return this.navList.indexOf(nav) !== -1;
    }

    get navMapGet(): NavApiMapType[] {
        return toJS(this._navMap);
    }

    /**
     * @todo Inspect usage
     */
    getModuleApiUri(url: string): string {

        if (url.length < 1) {
            return '';
        }

        let uri = '';
        this.navMapGet.map((v: NavApiMapType) => {
            if (v?.c === url) {
                uri = v?.s;
            }

            return false;
        });

        return uri;
    }

    set appError(error: string | undefined) {
        this._appError = error;
    }

    get appError(): string | undefined {
        return this._appError;
    }

    appErrorReset(): void {
        this.appError = undefined;
    }

    set appDebugMessage(error: string | undefined) {
        this._appDebugMessage = error;
    }

    get appDebugMessage(): string | undefined {
        return this._appDebugMessage;
    }

    appDebugMessageReset(): void {
        this._appDebugMessage = undefined;
    }


    get navCurrentGet(): string {
        return localStorage.getItem('AppLastNav') ?? homePath;

        // const path = localStorage.getItem('AppLastNav') ?? homePath;
        // return AppModel.navIsAllowed(path) ? path : homePath;
    }

    navCurrentSet(path?: string): void {
        if (path && this.isAuthenticated) {
            localStorage.setItem(
                'AppLastNav',
                // this.navIsAllowed(path) ? path : homePath
                path
            );
        }
    }

    get user(): AppUser {
        return this._user;
    }

    get hb(): AppHandbook {
        return this._hb;
    }

    get profileBrandsKv(): Handbook {
        const brands = new Set(this.user.brandKeys);
        const brandFullMap = this.hb.getProjectMapByCompany(this.user.companyKey);

        return brandFullMap.filter(brand => brands.has(+brand.k));
    }
}

const AppModel = new AppStore();

export default AppModel;
