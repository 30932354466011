import Alert                                    from '@mui/material/Alert';
import {AlertColor, AlertPropsVariantOverrides} from '@mui/material/Alert/Alert';
import Snackbar                                 from '@mui/material/Snackbar';
import Typography                               from '@mui/material/Typography';
import {OverridableStringUnion}                 from '@mui/types';
import {observer}                               from 'mobx-react';
import React                                    from 'react';
import {_t}                                     from 'shared/lib/i18n/I18n';
import AppModel                                 from 'shared/model/AppModel';

interface SnackType {
    title?: string,
    body?: string
    open?: boolean;
    autoHideDuration?: number;
    width?: number;
    anchorOrigin?: any
    variant?: OverridableStringUnion<'standard' | 'filled' | 'outlined', AlertPropsVariantOverrides>;
    severity?: AlertColor;
    onClose?: any;
}

export const Snack = (
    {
        severity = 'info',
        onClose = () => {},
        title = '',
        body = '',
        autoHideDuration = 5000,
        open = false,
        anchorOrigin = {vertical: 'top', horizontal: 'right'},
        width = 400,
        variant = 'standard'

    }: SnackType) => (

    <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        sx={{width: width}}
        disableWindowBlurListener={true}
    >
        <Alert
            variant={variant}
            severity={severity}
            sx={{width: width}}
            onClose={onClose}
        >
            <Typography sx={{mt: 0, mb: 2}} display='block' variant='h6'>
                {_t(title)}
            </Typography>

            <Typography sx={{m: 0}} display='block' variant='h6'>
                {_t(body)}
            </Typography>

        </Alert>
    </Snackbar>
);

export const SnackError = (
    {
        title = 'Error',
        body = '',
        autoHideDuration = 5000,
        open = true,
        anchorOrigin = {vertical: 'top', horizontal: 'right'},
        onClose = () => {},
        width = 400,
        variant = 'filled'

    }: SnackType) => (
    <Snack
        title={title}
        body={body}
        autoHideDuration={autoHideDuration}
        open={open}
        anchorOrigin={anchorOrigin}
        onClose={onClose}
        width={width}
        variant={variant}
        severity='error'
    />
);

const AppSnackError = () =>
    <SnackError
        open={!!AppModel.appError}
        onClose={() => AppModel.appErrorReset()}
        body={AppModel.appError}
    />;

export default observer(AppSnackError);
