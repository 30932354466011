import { observer }   from 'mobx-react';
import React          from 'react';
import AppModel       from 'shared/model/AppModel';
import { SnackError } from 'shared/ui/snack/index';

const AppSnackDebug = () =>
    <SnackError
        open={!!AppModel.appDebugMessage}
        onClose={() => AppModel.appDebugMessageReset()}
        body={AppModel.appDebugMessage}
        autoHideDuration={60000}
    />;

export default observer(AppSnackDebug);