import ShoppingCartOutlined from '@ant-design/icons/ShoppingCartOutlined';
import urlConfigInternal    from 'shared/config/url-config-internal';

const icons = {
    ShoppingCartOutlined
};

const accountingTransaction = {
    id   : 'accounting-transactions',
    title: 'Accounting Transactions',
    type : 'item',
    url  : urlConfigInternal.accountingTransaction,
    icon : icons.ShoppingCartOutlined
};

export default accountingTransaction;
