import PeopleIcon        from '@mui/icons-material/People';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    PeopleIcon
};

const users = {
    id   : 'users',
    title: 'Backend users [sa]',
    type : 'item',
    url  : urlConfigInternal.usersManager,
    icon : icons.PeopleIcon
};

export default users;
