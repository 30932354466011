export default function FormHelperText(theme: any) {
    return {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize : '0.65rem',
                    marginTop: '1px',
                    // lineHeight   : .5,
                    lineHeight: 1,
                    // lineHeight   : 1.2,
                    '&.Mui-error': {
                        // color: theme.palette.grey[500],
                        // color: theme.palette.warning.main
                        // color: theme.palette.warning.dark
                        // color: theme.palette.error.light
                    }
                },
            }
        }
    };
}
