export default function LinearProgress() {
    return {
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    height      : 3,
                    // borderRadius: 100
                },
                bar : {
                    // borderRadius: 100
                }
            }
        }
    };
}
