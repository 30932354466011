import Box                 from '@mui/material/Box';
import { observer }        from 'mobx-react';
import React               from 'react';
import AppModel            from 'shared/model/AppModel';
import { SpinnerDataGrid } from 'shared/ui/Icons/Icons';
import menuItem            from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/items';
import NavItem             from 'shared/ui/layout/MainLayout/Drawer/DrawerContent/Navigation/NavItem';

const Navigation = () => {
    let navGroups =
        menuItem.items
            .filter(data => AppModel.navIsAllowed(data.url))
            .map((item) => {
                switch (item.type) {
                    default:
                        return <NavItem key={item.id} item={item} level={1} />;
                }
            });

    return AppModel.isReady && navGroups ?
        <Box sx={{ pt: 2 }}>{navGroups}</Box> :
        <SpinnerDataGrid animate={true} sx={{ color: 'primary.lighter', opacity: 0.1 }} />;
};

export default observer(Navigation);
