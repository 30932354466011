import GamesIcon         from '@mui/icons-material/Games';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    GamesIcon
};

const gameBrands = {
    id   : 'gameBrands',
    title: 'Games',
    type : 'item',
    url  : urlConfigInternal.gameBrands,
    icon : icons.GamesIcon
};

export default gameBrands;
