import CurrencyExchangeIcon   from '@mui/icons-material/CurrencyExchange';
import LoadingButton          from '@mui/lab/LoadingButton';
import Alert                  from '@mui/material/Alert';
import Box                    from '@mui/material/Box';
import Container              from '@mui/material/Container';
import Typography             from '@mui/material/Typography';
import Grid                   from '@mui/material/Unstable_Grid2';
import {action}               from 'mobx';
import {observer}             from 'mobx-react';
import * as React             from 'react';
import {Component}            from 'react';
import {numberFormatByLocale} from 'shared/lib/i18n/Formatter';
import {_t}                   from 'shared/lib/i18n/I18n';
import AppModel               from 'shared/model/AppModel';
import {SelectorInput}        from 'shared/ui/form/SelectorInput';
import {TextInput}            from 'shared/ui/form/TextInput';
import currencyExchangeApi    from 'widgets/Tools/currency-converter/CurrencyConverterApi';
import CurrencyConverterStore from 'widgets/Tools/currency-converter/CurrencyConverterModel';

const ConverterResult = ({error, ratio, result}: {
    error: string,
    ratio: string,
    result: string
}) => {

    let c = <></>;

    if (error) {
        c = <Alert severity="error" sx={{mt: 2, overflow: 'hidden'}}>
            {_t('app.inconvenience')}
        </Alert>
    } else if (ratio) {
        c = <>
            <Typography variant="h3" align='center'
                        sx={{height: '35px', mt: 2, mb: 1, overflow: 'hidden', fontSize: '33px'}}>
                {numberFormatByLocale(result)}
            </Typography>

            <Typography variant="body2" align='center'
                        sx={{height: '20px', mt: 0, overflow: 'hidden', color: 'success.light'}}>
                {_t('Rate')}
            </Typography>

            <Typography variant="body2" align='center'
                        sx={{height: '20px', mt: 0, overflow: 'hidden', color: 'text.secondary'}}>
                {numberFormatByLocale(ratio, 5)}
            </Typography>
        </>
    }

    return c;
}

interface CurrencyConverterWidgetProps {
    store: typeof CurrencyConverterStore
}

class CurrencyConverterWidget<T extends CurrencyConverterWidgetProps> extends Component<T, {}> {

    private _model: typeof CurrencyConverterStore

    constructor(props: T) {
        super(props);
        this._model = props.store;
    }

    render() {

        const currencyList = AppModel.hb.kvCurrencyConverter;

        return <Container sx={{
            m             : 0,
            p             : 0,
            display       : 'flex',
            flexDirection : 'column',
            justifyContent: 'center',
        }}>
            <Grid container
                  spacing={2}
                  rowSpacing={2}
                  sx={{m: 0, p: 0}}
            >

                <Grid xs={12}>
                    <TextInput
                        label={'Amount'}
                        onChange={action((e: any) => {
                            const v = e.target.value.replace(/[^0-9.]/g, '');
                            if (!isNaN(v))
                                this._model.amount = v;
                        })}
                        value={this._model.amount}
                    />
                </Grid>

                <Grid xs={6}>
                    <SelectorInput
                        onChange={action((e: any) => {
                            this._model.from = e.target.value;
                        })}
                        required
                        sx={{m: 0, p: 0}}
                        fullWidth={true}
                        selected={this._model.from}
                        title={'From'}
                        optionMap={currencyList}
                    />
                </Grid>

                <Grid xs={6}>
                    <SelectorInput
                        required
                        sx={{m: 0, p: 0}}
                        fullWidth={true}
                        selected={this._model.to}
                        title={'To'}
                        optionMap={currencyList}
                        onChange={action((e: any) => {
                            this._model.to = e.target.value;
                        })}
                    />
                </Grid>

            </Grid>

            <LoadingButton
                variant='contained'
                color='success'
                size='large'
                sx={{m: 0, p: 0, minHeight: '52px'}}
                loading={this._model.loading}
                loadingPosition="center"
                startIcon={<CurrencyExchangeIcon/>}
                onClick={action(() => {

                    this._model.loading = true;
                    const params        = {
                        'from'  : this._model.from,
                        'to'    : this._model.to,
                        'amount': this._model.amount,
                    }

                    currencyExchangeApi(params)
                        .then(action((response: any) => {
                            this._model.errorMessage     = '';
                            const {data: {rate, amount}} = response;
                            if (rate && amount) {
                                this._model.amountRet = amount;
                                this._model.ratioRet  = rate;
                            } else {
                                this._model.errorMessage = 'Try later'
                            }
                        }))
                        .finally(action(() => {
                            this._model.loading = false;
                        }))
                        .catch((e: any) => {
                            this._model.modelReset();
                        });
                })}
            >
                {_t('Convert')}
            </LoadingButton>

            <Box sx={{height: '104px'}}>
                <ConverterResult
                    error={this._model.errorMessage}
                    ratio={this._model.ratioRet}
                    result={this._model.amountRetCalculated}
                />
            </Box>

        </Container>
    }
}

export default observer(CurrencyConverterWidget);
