import GamesIcon         from '@mui/icons-material/Games';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    GamesIcon
};

const gameTags = {
    id   : 'gameTags',
    title: 'Game Tags',
    type : 'item',
    url  : urlConfigInternal.gameTags,
    icon : icons.GamesIcon
};

export default gameTags;
