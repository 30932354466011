import lightBlue from '@mui/material/colors/lightBlue';
import {Theme}   from '@mui/material/styles/createTheme';
// import {blueZodiac} from 'shared/ui/themes/palette';

export default function Tabs(theme: Theme) {
    return {
        MuiTabs: {
            styleOverrides: {
                root     : {
                    minHeight      : '28px',
                    backgroundColor: lightBlue[50],
                },
                indicator: {
                    height              : 4,
                    borderTopLeftRadius : 13,
                    borderTopRightRadius: 13,
                    // backgroundColor: blueZodiac
                    backgroundColor: theme.palette.primary.light
                },
                vertical : {
                    overflow: 'visible'
                }
            }
        }
    };
}
