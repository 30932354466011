import App               from 'app/App';
import { configure }     from 'mobx';
import React             from 'react';
import ReactDOM          from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/app.scss';

configure({ enforceActions: 'observed' });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>

    // <React.StrictMode>
    //     <BrowserRouter>
    //         <App />
    //     </BrowserRouter>
    // </React.StrictMode>
);
