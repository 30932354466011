import PaidIcon          from '@mui/icons-material/Paid';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    PaidIcon
};

const paymentSystems = {
    id   : 'paymentSystems',
    title: 'Payment Systems [sa]',
    type : 'item',
    url  : urlConfigInternal.paymentSystems,
    icon : icons.PaidIcon
};

export default paymentSystems;
