import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined';
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import useTheme from '@mui/material/styles/useTheme';
import Toolbar from '@mui/material/Toolbar';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import AppBarStyled from 'shared/ui/layout/MainLayout/Header/AppBarStyled';
import HeaderContent from 'shared/ui/layout/MainLayout/Header/HeaderContent';

const Header = ({open, handleDrawerToggle}) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

    const iconBackColor = 'grey.100';
    const iconBackColorOpen = 'grey.200';

    // common header
    const mainHeader = (
        <Toolbar sx={{
            minHeight: '44px',
            height: '44px',
            py: 1
        }}>
            <IconButton
                disableRipple
                aria-label='open drawer'
                onClick={handleDrawerToggle}
                edge='start'
                color='secondary'
                sx={{color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: {xs: 0, lg: -2}}}
            >
                {!open ? <MenuUnfoldOutlined/> : <MenuFoldOutlined/>}
            </IconButton>
            <HeaderContent/>
        </Toolbar>
    );

    // app-bar params
    const appBar = {
        position: 'fixed',
        color: 'inherit',
        elevation: 0,
        sx: {
            borderBottom: `1px solid ${theme.palette.divider}`
            // boxShadow: theme.customShadows.z1
        }
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

Header.propTypes = {
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

export default Header;
