import { Observer }                    from 'mobx-react';
import React, { lazy }                 from 'react';
import { useLocation }                 from 'react-router-dom';
import urlConfigInternal, { homePath } from 'shared/config/url-config-internal';
import AppModel                        from 'shared/model/AppModel';
import MainLayout                      from 'shared/ui/layout/MainLayout';
import Loadable                        from 'shared/ui/Loadable';

const DashboardModule = Loadable(lazy(() => import('module/dashboard/Dashboard.Module')));
const OrganizationModule = Loadable(lazy(() => import('module/organization/OrganizationModule')));
const UserModule = Loadable(lazy(() => import('module/user/UserModule')));
const RoleModule = Loadable(lazy(() => import('module/role/RoleModule')));
const PlayerManagerModule = Loadable(lazy(() => import('module/playerManager/PlayerManagerModule')));
const GameIntegratorModule = Loadable(lazy(() => import('module/gameIntegrator/GameIntegratorModule')));
const GameProviderModule = Loadable(lazy(() => import('module/gameProvider/GameProviderModule')));
const GameBrandProviderModule = Loadable(lazy(() => import('module/gameBrandProvider/GameBrandProviderModule')));
const GamesModule = Loadable(lazy(() => import('module/games/GamesModule')));
const GameTagModule = Loadable(lazy(() => import('module/gameTag/GameTagModule')));
const GameBrandModule = Loadable(lazy(() => import('module/gameBrand/GameBrandModule')));
const PaymentSystemModule = Loadable(lazy(() => import('module/paymentSystem/PaymentSystemModule')));
const PaymentSystemMethodModule = Loadable(lazy(() => import('module/paymentSystemMethod/PaymentSystemMethodModule')));
const PaymentSystemMethodBrandModule = Loadable(lazy(() => import('module/paymentSystemMethodBrand/PaymentSystemMethodBrandModule')));
const PaymentSystemBrandModule = Loadable(lazy(() => import('module/paymentSystemBrand/PaymentSystemBrandModule')));
const AccountingTransactionCategoryModule = Loadable(lazy(() => import('module/accountingTransactionCategory/AccountingTransactionCategoryModule')));
const AccountingTransactionModule = Loadable(lazy(() => import('module/accountingTransaction/AccountingTransactionModule')));
const AdvertisingAffiliateModule = Loadable(lazy(() => import('module/advertisingAffiliate/AdvertisingAffiliateModule')));
const ShiftScheduleManagerModule = Loadable(lazy(() => import('module/shiftScheduleManager/ShiftScheduleManagerModule')));
const ShiftEmployeeMonthPlanModule = Loadable(lazy(() => import('module/shiftEmployeeMonthPlan/ShiftEmployeeMonthPlanModule')));
const FreespinOfferModule = Loadable(lazy(() => import('module/freespinOffer/FreespinOfferModule')));
const PlayerFreespinsByBrandModule = Loadable(lazy(() => import('module/playerFreespins/PlayerFreespinsModule')));
const BrandDepositTransactionsModule = Loadable(lazy(() => import('module/BrandDepositTransaction/BrandDepositTransactionsModule')));
const BrandWithdrawalTransactionsModule = Loadable(lazy(() => import('module/BrandWithdrawalTransaction/BrandWithdrawalTransactionsModule')));
const BrandBetWinTransactionsModule = Loadable(lazy(() => import('module/BrandBetWinTransactions/BrandBetWinTransactionsModule')));
const MessagingTemplateModule = Loadable(lazy(() => import('module/messagingTemplate/MessagingTemplateModule')));
const ProtoSegmentationModule = Loadable(lazy(() => import('module/protoSegmentation/ProtoSegmentationModule')));

const ProtectedRoutes = {
    path: homePath,
    element: <MainLayout />,
    children: [
        // {
        //     path   : urlConfigInternal.playerInfo+'/:id',
        //     element: <RequireAuth><PlayerInfoModule /></RequireAuth>
        // },
        {
            path: urlConfigInternal.protoSegmentation,
            element: <RequireAuth><ProtoSegmentationModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.messagingTemplate,
            element: <RequireAuth><MessagingTemplateModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.dashboard,
            element: <RequireAuth><DashboardModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.organizationManager,
            element: <RequireAuth><OrganizationModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.usersManager,
            element: <RequireAuth><UserModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.rolesManager,
            element: <RequireAuth><RoleModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.playerProfiles,
            element: <RequireAuth><PlayerManagerModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.gameIntegrator,
            element: <RequireAuth><GameIntegratorModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.gameProvider,
            element: <RequireAuth><GameProviderModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.gameBrandProvider,
            element: <RequireAuth><GameBrandProviderModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.games,
            element: <RequireAuth><GamesModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.gameTags,
            element: <RequireAuth><GameTagModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.gameBrands,
            element: <RequireAuth><GameBrandModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.paymentSystems,
            element: <RequireAuth><PaymentSystemModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.paymentSystemMethod,
            element: <RequireAuth><PaymentSystemMethodModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.paymentSystemMethodBrand,
            element: <RequireAuth><PaymentSystemMethodBrandModule /></RequireAuth>
        },

        {
            path: urlConfigInternal.paymentSystemBrand,
            element: <RequireAuth><PaymentSystemBrandModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.accountingTransactionCategories,
            element: <RequireAuth><AccountingTransactionCategoryModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.accountingTransaction,
            element: <RequireAuth><AccountingTransactionModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.advertisingAffiliates,
            element: <RequireAuth><AdvertisingAffiliateModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.shiftScheduleManager,
            element: <RequireAuth><ShiftScheduleManagerModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.shiftEmployeeMonthPlan,
            element: <RequireAuth><ShiftEmployeeMonthPlanModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.freespinOfferManager,
            element: <RequireAuth><FreespinOfferModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.playerFreespinByBrand,
            element: <RequireAuth><PlayerFreespinsByBrandModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.brandDepositTransactions,
            element: <RequireAuth><BrandDepositTransactionsModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.brandWithdrawalTransactions,
            element: <RequireAuth><BrandWithdrawalTransactionsModule /></RequireAuth>
        },
        {
            path: urlConfigInternal.brandBetWinTransactions,
            element: <RequireAuth><BrandBetWinTransactionsModule /></RequireAuth>
        }
    ]
};
export default ProtectedRoutes;

function RequireAuth({ children }: {
    children: React.JSX.Element
}): React.JSX.Element {

    const location = useLocation();
    AppModel.navCurrentSet(location?.pathname);

    return (
        <Observer>{() => {
            return children;
        }}</Observer>
    );

    // return (
    //     <Observer>{() => {
    //         return AppModel.isAuthenticated ?
    //             (children) :
    //             (<Navigate to={loginPath} replace state={{ path: location.pathname }} />);
    //     }}</Observer>
    // );

}
