import { Theme } from '@mui/material/styles/createTheme';

export default function Tab(theme: Theme) {
    return {
        MuiGrid2: {
            defaultProps  : {
                // margin: 0
            },
            styleOverrides: {
                root: {}
            }
        }
    };
}
