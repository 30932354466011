// import {AdapterDateFns}       from '@mui/x-date-pickers/AdapterDateFns';
import { AdapterDateFns }       from '@mui/x-date-pickers/AdapterDateFnsV3'; // after update  "date-fns": "^2.30.0" to "date-fns": "^3.3.1",
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Routes                   from 'app/routes';
import React, { useEffect }     from 'react';
import { useNavigate }          from 'react-router-dom';
import AppModel                 from 'shared/model/AppModel';
import ScrollTop                from 'shared/ui/ScrollTop';
import AppSnackError            from 'shared/ui/snack';
import AppSnackDebug            from 'shared/ui/snack/AppSnackDebug';
import ThemeCustomization       from 'shared/ui/themes';

const App = () => {

    const navigate = useNavigate();

    useEffect(
        () => {
            AppModel.boot();
        },
        [navigate]
    );

    return (
        <ThemeCustomization>

            <LocalizationProvider dateAdapter={AdapterDateFns}>

                <AppSnackError />

                <AppSnackDebug />

                <ScrollTop>

                    <Routes />

                </ScrollTop>

            </LocalizationProvider>

        </ThemeCustomization>
    );
};

export default App;
