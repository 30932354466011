import CancelIcon       from '@mui/icons-material/Cancel';
import EditIcon         from '@mui/icons-material/Edit';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import {blue}           from '@mui/material/colors';
import IconButton       from '@mui/material/IconButton';
import {Theme}          from '@mui/material/styles';
import Grid             from '@mui/material/Unstable_Grid2';
import {SxProps}        from '@mui/system';
import * as React       from 'react';

export const EditorIcon       = () => <EditIcon sx={{color: blue[500]}}/>;
export const DialogCloserIcon = () => <IconButton
    sx={{
        // color : 'primary.lighter',
        color : 'white',
        width : '20px',
        height: '20px',
    }}
>
    <CancelIcon sx={{fontSize: '100%'}}/>
</IconButton>;

export const SpinnerIcon = () => <SyncOutlinedIcon sx={{}}/>;

interface SpinnerActiveIconProps {
    animate?: boolean;
    fontSize?: 'inherit' | 'large' | 'medium' | 'small';
    sx?: SxProps<Theme>;
}

export const SpinnerActiveIcon = (
    {
        animate = false,
        fontSize = undefined
    }: SpinnerActiveIconProps
) =>
    <SyncOutlinedIcon
        fontSize={fontSize}
        sx={animate ? {
            animation        : 'spin 0.7s linear infinite',
            '@keyframes spin': {
                '0%': {
                    transform: 'rotate(0deg)'
                },

                '100%': {
                    transform: 'rotate(360deg)'
                }
            }
        } : {}}
    />;


export const SpinnerDataGrid = (
    {
        animate = false,
        sx = {}

    }: SpinnerActiveIconProps) =>
    <Grid
        container
        spacing={0}
        direction='column'
        alignItems='center'
        justifyContent='center'
        sx={{...{minHeight: '45vh', fontSize: '160px', opacity: 0.03}, ...sx}}
    >
        <Grid>
            <SpinnerActiveIcon
                animate={animate}
                fontSize='inherit'
            />
        </Grid>
    </Grid>;
