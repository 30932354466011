import Box                 from '@mui/material/Box';
import Checkbox            from '@mui/material/Checkbox';
import FormControl         from '@mui/material/FormControl';
import InputLabel          from '@mui/material/InputLabel';
import MenuItem            from '@mui/material/MenuItem';
import Select              from '@mui/material/Select';
import React               from 'react';
import {_t}                from 'shared/lib/i18n/I18n';
import EditorSemaphore     from 'shared/model/EditorSemaphoreModel';
import {ClearableSmart}    from 'shared/ui/form/Clearable';
import {SysColors}         from 'shared/ui/form/InputTypes';
import {SelectorInputType} from 'shared/ui/form/SelectorInputType';

export const SelectorInput = (
    {
        title,
        optionMap,
        selected,
        onChange,
        onClear,
        withClear=false,
        onClose,
        errorVal,
        size,
        sx,
        variant,
        disabled,
        ID = '',
        fullWidth = true,
        required = false,
        translateOff = true,
        multiple = false,
        withCheckbox = false,
        renderValue = null
    }: SelectorInputType) => {
    let optionList = [];

    if (disabled === undefined) {
        disabled = EditorSemaphore.isProcessing;
    }

    if (!required) {
        // optionList.push(<MenuItem key={title + '-empty'} value=''><em>{_t('app.empty.value')}</em></MenuItem>);
        optionList.push(<MenuItem key={title + '-empty'} value=''><em><p></p></em></MenuItem>);
    }

    const hasSelected: boolean = !!selected?.length;
    Object.entries(optionMap).forEach(([i, obj]) => {
        let key: any  = obj?.k;
        let isChecked = hasSelected && selected?.indexOf(key) !== -1;
        optionList.push(
            <MenuItem
                key={title + '-' + key}
                value={key}
            >
                {withCheckbox ? <Checkbox checked={isChecked} sx={{p: '1px'}}/> : null}
                {_t(obj.v, translateOff)}
            </MenuItem>
        );
    });

    if (ID === '' || !ID) {
        ID = title;
        ID.replace(/[^a-z0-9]/gi, '-');
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
            <Box sx={{width: '100%'}}>
                <FormControl
                    disabled={disabled}
                    error={!!errorVal}
                    required={required}
                    variant='standard'
                    fullWidth={fullWidth}
                    sx={{mb: 2}}
                >
                    <InputLabel
                        shrink={true}
                        id={ID + '-label'}
                    >
                        {_t(title)}
                    </InputLabel>

                    <Select
                        labelId={ID + '-label'}
                        id={ID}
                        value={selected}
                        onChange={onChange}
                        onClose={onClose}
                        label={_t(title)}
                        size={size}
                        sx={sx}
                        variant={variant}
                        multiple={multiple}
                        renderValue={renderValue}
                    >
                        {optionList}
                    </Select>
                </FormControl>

            </Box>

            {onClear ?
                <Box sx={{alignItems: 'self-end'}}>
                    <ClearableSmart
                        onClear={onClear}
                        value={selected}
                        color={errorVal ? SysColors.Error : SysColors.Default}
                    />
                </Box> : null
            }

        </Box>
    );
};
