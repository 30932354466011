import {Theme} from '@mui/material/styles/createTheme';

export default function Tab(theme: Theme) {
    return {
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight                   : '28px',
                    padding                     : '8px 16px',
                    color                       : theme.palette.text.primary,
                    margin                      : `${theme.spacing(2)}px`,
                    minWidth                    : 0,
                    [theme.breakpoints.up('md')]: {
                        minWidth: 0
                    }
                }
            }
        }
    };
}
