import { TransitionProps } from '@mui/material/transitions';
import Zoom                from '@mui/material/Zoom';
import React               from 'react';

export const TransitionZoom = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Zoom unmountOnExit in={true} ref={ref} {...props} />;
});
