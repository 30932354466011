import {DollarOutlined}        from '@ant-design/icons';
import Box                     from '@mui/material/Box';
import Fade                    from '@mui/material/Fade';
import useTheme                from '@mui/material/styles/useTheme';
import Toolbar                 from '@mui/material/Toolbar';
import useMediaQuery           from '@mui/material/useMediaQuery';
import {action}                from 'mobx';
import {observer}              from 'mobx-react';
import React, {useEffect}      from 'react';
import {Outlet}                from 'react-router-dom';
import AppModel                from 'shared/model/AppModel';
import NavigationModel         from 'shared/model/NavigationModel';
import {DialogueWidget}        from 'shared/ui/dialogue/DialogueWidget';
import {SpinnerDataGrid}       from 'shared/ui/Icons/Icons';
import Drawer                  from 'shared/ui/layout/MainLayout/Drawer';
import Header                  from 'shared/ui/layout/MainLayout/Header';
import CurrencyConverterStore  from 'widgets/Tools/currency-converter/CurrencyConverterModel';
import CurrencyConverterWidget from 'widgets/Tools/currency-converter/CurrencyConverterWidget';

const MainLayout = () => {

    const isDrawerOpen       = NavigationModel.expandedGet;
    const theme              = useTheme();
    const matchDownLG        = useMediaQuery(theme.breakpoints.down('xl'));
    const handleDrawerToggle = () => { NavigationModel.expandedToggle();};

    // set media wise responsive drawer
    useEffect(() => {
        // NavigationModel.expandedSet(!matchDownLG);
    }, [matchDownLG]);

    return AppModel.isReady ? (

        <Fade in={true} appear={true} timeout={1200}>
            <Box sx={{
                display: 'flex',
                width  : '100%'

            }}>
                <Header open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle}/>
                <Drawer open={isDrawerOpen} handleDrawerToggle={handleDrawerToggle}/>
                <Box component='main'
                     sx={{

                         width   : '100%',
                         flexGrow: 1,
                         // p       : {xs: 2, sm: 3},
                         p: {xs: 1, sm: 1, md: 1, lg: 1, xl: 1},
                         m: {xs: 0, sm: 0, md: 0, lg: 0, xl: 0},
                     }}
                >
                    <Toolbar sx={{
                        minHeight: '50px',
                        height   : '50px',
                        p        : 0
                    }}/>
                    <Outlet/>

                    <DialogueWidget
                        title='Currency converter'
                        headerIcon={<Box sx={{m: 0, p: 0, color: 'success.light'}}><DollarOutlined/></Box>}
                        open={CurrencyConverterStore.widget}
                        onClose={action(() => {CurrencyConverterStore.widgetClose()})}
                        onConfirm={action(() => {CurrencyConverterStore.widgetClose()})}
                        maxWidth='xs'
                        sx={{top: '2%'}}
                    >
                        <CurrencyConverterWidget store={CurrencyConverterStore}/>
                    </DialogueWidget>

                </Box>
            </Box>
        </Fade>
    ) : <SpinnerDataGrid animate={true} sx={{fontSize: '460px', m: 'auto', opacity: .02}}/>;
    // ) : <Outlet/>;
};

export default observer(MainLayout);
