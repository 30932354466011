import {action, computed, makeObservable, observable, toJS} from 'mobx';

class NavigationStore {
    constructor() {
        makeObservable(this, {
            expanded      : observable,
            expandedGet   : computed,
            expandedToggle: action,
            expandedSet   : action,
            currentItem   : observable,
            current       : computed,
            currentSet    : action
        });

        this.expanded = true;
    }

    expanded: boolean     = false;
    currentItem: String[] = ['dashboard'];

    get expandedGet(): boolean {
        return toJS(this.expanded);
    }

    expandedSet(isExpanded: boolean): void {
        this.expanded = isExpanded;
    }

    expandedToggle(): void {
        const isExpanded = this.expandedGet;
        this.expanded    = !isExpanded;
    }

    get current(): String[] {
        return toJS(this.currentItem);
    }

    currentSet(menuItem: String[]): void {
        this.currentItem = menuItem;
    }
}

const NavigationModel = new NavigationStore();

export default NavigationModel;
