import Card           from '@mui/material/Card';
import CardContent    from '@mui/material/CardContent';
import CardHeader     from '@mui/material/CardHeader';
import Typography     from '@mui/material/Typography';
import Divider        from '@mui/material/Divider';
import useTheme       from '@mui/material/styles/useTheme';
import { forwardRef } from 'react';

const headerSX = {
    p                        : 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};

interface MainCardProps {
    border: boolean,
    boxShadow: boolean,
    contentSX: any,
    darkTitle: boolean,
    divider: boolean,
    elevation: number,
    secondary: any,
    shadow: string,
    sx: any,
    title: string,
    codeHighlight: boolean,
    content: boolean,
    children: any
}


const MainCard = forwardRef(
    (
        {
            border = true,
            boxShadow,
            children,
            content = true,
            contentSX = {},
            darkTitle,
            divider = true,
            elevation,
            secondary,
            shadow,
            sx = {},
            title,
            codeHighlight,
            ...others
        }: MainCardProps,
        ref: any
    ) => {
        const theme: any = useTheme();
        boxShadow = theme.palette.mode === 'dark' ? boxShadow || true : boxShadow;

        return (
            <Card
                elevation={elevation || 0}
                ref={ref}
                {...others}
                sx={{
                    ...sx,
                    border      : border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor : theme.palette.mode === 'dark' ? theme.palette.divider : theme.palette.grey.A800,
                    boxShadow   : boxShadow && (!border || theme.palette.mode === 'dark') ? shadow || theme.customShadows.z1 : 'inherit',
                    ':hover'    : {
                        boxShadow: boxShadow ? shadow || theme.customShadows.z1 : 'inherit'
                    },
                    '& pre'     : {
                        m         : 0,
                        p         : '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize  : '0.75rem'
                    }
                }}
            >
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader sx={headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={title} action={secondary} />
                )}
                {darkTitle && title && (
                    <CardHeader sx={headerSX} title={<Typography variant='h3'>{title}</Typography>} action={secondary} />
                )}

                {/* content & header divider */}
                {title && divider && <Divider />}

                {/* card content */}
                {content && <CardContent sx={contentSX}>{children}</CardContent>}
                {!content && children}

            </Card>
        );
    }
);

export default MainCard;
