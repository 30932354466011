import {Breakpoint} from '@mui/system';
import * as React   from 'react';
import Dialogue     from 'shared/ui/dialogue/Dialogue';

type DialogueWidgetType = {
    open: boolean,
    onClose: any,
    onConfirm: any,
    title: string,
    maxWidth?: Breakpoint | false,
    children: any,
    sx?: any,
    headerIcon?: any,
}

export const DialogueWidget = (
    {
        open,
        onClose,
        onConfirm,
        title,
        children,
        maxWidth = 'sm',
        sx = {},
        headerIcon
    }: DialogueWidgetType
) =>
    <Dialogue
        title={title}
        open={open}
        loading={false}
        onClose={onClose}
        onConfirm={onConfirm}
        disableActions={true}
        closeFromTitle={true}
        maxWidth={maxWidth}
        sx={sx}
        headerIcon={headerIcon}
    >
        {children}
    </Dialogue>
