import { action }           from 'mobx';
import LoginModel           from 'module/auth/lib/LoginModel';
import { NavigateFunction } from 'react-router/dist/lib/hooks';
import { apiResponse }      from 'shared/api/ApiResponse';
import appRequest           from 'shared/api/moduleApi';
import AppModel             from 'shared/model/AppModel';

class AuthenticationService {
    private readonly _endpoint: string;

    constructor() {
        this._endpoint = 'auth';
    }

    login(model: LoginModel, navigate: NavigateFunction): void {
        model.loadingStart();
        appRequest
            .post(this._endpoint + '/login', model.formDataExport)
            .then(action((response: apiResponse) => {
                const { ok, data } = response;
                if (!ok) {
                    model.formErrorSet = data;
                } else {
                    AppModel.boot(() => {
                        navigate(AppModel.navCurrentGet);
                    });
                }
            }))
            .finally(() => {
                model.loadingStop();
            })
            .catch((e) => {});
    }

    logout() {
        appRequest
            .post(this._endpoint + '/logout', {})
            .then(action(() => {
                AppModel.shutdown();
            }))
            .catch(() => {});
    }
}

const AuthService = new AuthenticationService();

export default AuthService;
