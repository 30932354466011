import ClearIcon                             from '@mui/icons-material/Clear';
import IconButton                            from '@mui/material/IconButton';
import {Observer}                            from 'mobx-react';
import React                                 from 'react';
import {ClearableSmartType}                  from 'shared/ui/form/ClearableType';
import {ErrorValType, SysColors, Visibility} from 'shared/ui/form/InputTypes';

type FilterClearableType = {
    onClear: any;
    value: any;
}

type ClearableType = {
    onClear: any;
    visibility: Visibility
}

//@todo check if need Observer
export const ClearableSmart = ({onClear, value, color}: ClearableSmartType): React.JSX.Element => <Observer>{() =>
    <IconButton
        onClick={onClear}
        size='small'
        color={color}
        sx={{
            m         : 0,
            p         : 0,
            visibility: (!onClear || !value || value === '') ? Visibility.Hidden : Visibility.Visible,
            left      : '-10px',
            top       : '-2px',
            width     : '10px',
            height    : '10px',
        }}
    >
        <ClearIcon sx={{fontSize: '10px', opacity: 0.5}}/>
    </IconButton>
}</Observer>;

export const Clearable = ({onClear, visibility = Visibility.Hidden}: ClearableType): React.JSX.Element => <Observer>{() =>
    <IconButton
        onClick={onClear}
        size='small'
        color='default'
        sx={{
            m         : 0,
            p         : 0,
            visibility: visibility,
            left      : '-10px',
            top       : '-2px',
            width     : '10px',
            height    : '10px',
        }}
    >
        <ClearIcon sx={{fontSize: '10px', opacity: 0.5}}/>
    </IconButton>
}</Observer>;


export const ClearableDispatcher = ({onClear, value, errorVal = ''}: ErrorValType & FilterClearableType) => {

    if (!onClear) {
        return null;
    }

    return <ClearableSmart
        onClear={onClear}
        value={value}
        color={errorVal ? SysColors.Error : SysColors.Default}
    />
};
