export default function InputBase() {
    return {
        MuiInputBase: {
            styleOverrides: {
                root : {
                    // // Not working
                    // '&::before': {
                    //     content     : '""',
                    //     borderBottom: '4px solid rgba(0, 0, 0, 0.42)'
                    // },
                },
                input: {
                    padding: '0 0 2px 0',
                }
            }
        }
    };
}
