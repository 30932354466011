import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import blueGrey from '@mui/material/colors/blueGrey';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import {forwardRef, useEffect} from 'react';
import {Link} from 'react-router-dom';
import MenuSideDrawer from 'shared/model/NavigationModel';
import {colorBase} from 'shared/ui/themes/palette';

const NavItem = ({item, level}) => {

    const isDrawerOpen = MenuSideDrawer.expandedGet;
    const menuActiveID = MenuSideDrawer.current;
    const theme = useTheme();

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget}/>)
    };
    if (item?.external) {
        listItemProps = {component: 'a', href: item.url, target: itemTarget};
    }

    const navItemClickHandler = (id) => {
        MenuSideDrawer.currentSet([id]);
    };

    const Icon = item.icon;
    const itemIcon = item.icon ? <Icon style={{fontSize: isDrawerOpen ? '1rem' : '1.25rem'}}/> : false;
    const isSelected = menuActiveID.findIndex((id) => id === item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        if (document.location.pathname.toString() === item.url) {
            MenuSideDrawer.currentSet([item.id]);
        }
        // eslint-disable-next-line
    }, []);

    const textColor = blueGrey[50];
    const iconSelectedColor = 'primary.main';
    const backgroundLight = colorBase.MainDark2;

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            onClick={() => {
                navItemClickHandler(item.id);
            }}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                // pl: isDrawerOpen ? `${level * 28}px` : 1.5,
                pl: isDrawerOpen ? `${level * 10}px` : 0,
                // py: !isDrawerOpen && level === 1 ? 1.25 : 1, // See 'shared/ui/themes/overrides/ListItemButton'
                ...(isDrawerOpen && {
                    '&:hover': {
                        // bgcolor: 'primary.lighter'
                        bgcolor: backgroundLight
                    },
                    '&.Mui-selected': {
                        // borderRight: `2px solid ${theme.palette.primary.main}`,
                        borderRight: `3px solid ${theme.palette.primary.light}`,
                        bgcolor: 'primary.lighter',
                        // bgcolor: backgroundLight,
                        color: iconSelectedColor,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                            // bgcolor: backgroundLight
                        }
                    }
                }),
                ...(!isDrawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!isDrawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!isDrawerOpen &&
                            isSelected && {
                                bgcolor: 'primary.lighter',
                                '&:hover': {
                                    bgcolor: 'primary.lighter'
                                }
                            })
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography variant='h6' sx={{color: isSelected ? iconSelectedColor : textColor}}>
                            {item.title}
                        </Typography>
                    }
                />
            )}
            {(isDrawerOpen || (!isDrawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
                />
            )}
        </ListItemButton>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default observer(NavItem);
