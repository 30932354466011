import PaidIcon          from '@mui/icons-material/Paid';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    PaidIcon
};

const brandDepositTransactions = {
    id   : 'brandDepositTransactions',
    title: 'Deposit Transactions',
    type : 'item',
    url  : urlConfigInternal.brandDepositTransactions,
    icon : icons.PaidIcon
};

export default brandDepositTransactions;
